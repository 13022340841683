<template>
  <el-dialog
    title=""
    :visible="visible"
    :width="width"
    :top="top"
    :before-close="handleClose"
    custom-class="empty_dialog"
    :modal-append-to-body="modalAppendToBody"
    :append-to-body="appendToBody"
    :show-close="showClose"
  >
    <slot></slot>
    <span slot="footer" class="dialog-footer"></span>
  </el-dialog>
</template>
<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: "500px",
    },
    top: {
      type: String,
      default: "10vh",
    },
    modalAppendToBody: {
      type: Boolean,
      default: true,
    },
    appendToBody: {
      type: Boolean,
      default: false,
    },
    showClose: {
      type: Boolean,
      default: true,
    },
  },
  computed: {},
  data() {
    return {
      visible: this.value,
    };
  },
  watch: {
    value(val) {
      this.visible = val;
    },
  },
  mounted() {},
  unmounted() {},
  methods: {
    handleClose() {
      this.visible = false;
      this.$emit("input", false);
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .empty_dialog {
  border-radius: 0;
  background: none;
  .el-dialog__header {
    padding: 0;
    .el-dialog__headerbtn {
      right: 37px;
      top: 17px;
      .el-dialog__close {
        font-weight: bold;
      }
    }
  }
  .el-dialog__body {
    padding: 0;
  }
  .el-dialog__footer {
    padding: 0;
  }
}
</style>
