<template>
  <div class="hk_market">
    <img-div
      class-name="flex flex-hc"
      :img-url="$t(`marketMall.${market}Market.banner`)"
      height="300"
    />
    <div class="container">
      <div class="table fs-18">
        <div class="thead fw-600">
          <div class="tr flex">
            <div
              class="td flex1 flex flex-hc flex-vc"
              v-for="(head, i) in $t(
                `marketMall.${market}Market.use_table.head`
              )"
              :key="i"
            >
              {{ head }}
            </div>
          </div>
        </div>
        <div class="tbody">
          <div
            class="tr flex"
            v-for="(body, idx) in $t(
              `marketMall.${market}Market.use_table.body`
            )"
            :key="idx"
          >
            <div
              :class="[
                'td flex1 flex flex-hc flex-vc',
                bodyI === 0 ? 'fw-600 bg_lightBlue' : '',
              ]"
              v-for="(td, bodyI) in body.td"
              :key="idx + bodyI"
            >
              <!-- <img v-if="td.imgSrc" :src="td.imgSrc" alt="" /> -->
              <svg-icon
                :name="td.iconName"
                v-if="td.iconName"
                :size="18"
                icon-class="use_iconClass"
                :style="`color:var(--${
                  td.iconName === 'right_fill' ? 'greenColor' : 'redColor'
                })`"
              />
              <span>{{ td.name }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="use_img_list flex flex-jsb">
        <div
          class="use_img_list_item flex flex-v flex-vc"
          v-for="(use_img_list_item, use_img_list_i) in $t(
            `marketMall.${market}Market.use_img_list`
          )"
          :key="use_img_list_i"
        >
          <img class="top_img" :src="use_img_list_item.top_img" alt="" />
          <div
            class="bottom"
            :style="`${market === 'us' ? 'padding:0px 63px;' : ''}`"
          >
            <img
              class="bottom_img"
              :src="use_img_list_item.bottom_img"
              alt=""
            />
          </div>
        </div>
      </div>

      <div class="flex flex-v flex-vc">
        <img
          class="huikui_title"
          :src="$t(`marketMall.${market}Market.huikui_title`)"
          alt=""
        />
        <span class="huikui_desc">
          {{ $t(`marketMall.${market}Market.huikui_desc`) }}
          <span>{{ $t(`marketMall.${market}Market.huikui_desc_tip`) }}</span>
        </span>
      </div>
      <div class="huikui_list flex flex-jsb fs-18">
        <div
          class="huikui_list_item"
          v-for="(huikui_list_item, huikui_list_item_i) in $t(
            `marketMall.${market}Market.huikui_list_item`
          )"
          :key="huikui_list_item_i"
        >
          <div class="item_title fs-24 fw-600">
            {{ huikui_list_item.title }}
          </div>
          <div class="flex flex-jsb flex-vc" style="padding: 0 51px">
            <div class="flex flex-v flex-vc">
              <div class="item_img">
                <img
                  src="@/assets/v2/marketMall/fan@3x.png"
                  class="right_top_icon"
                  alt=""
                />
                <img
                  src="@/assets/v2/marketMall/phone.png"
                  class="main_img"
                  alt=""
                />
              </div>
              <span class="item_img_title">{{
                huikui_list_item.phone_title
              }}</span>
            </div>
            <span style="margin-top: -40px" class="fs-16">{{
              huikui_list_item.or
            }}</span>
            <div class="flex flex-v flex-vc">
              <div class="item_img">
                <img
                  src="@/assets/v2/marketMall/fan@3x.png"
                  class="right_top_icon"
                  alt=""
                />
                <img
                  src="@/assets/v2/marketMall/pc.png"
                  class="main_img"
                  alt=""
                />
              </div>
              <span class="item_img_title">{{
                huikui_list_item.pc_title
              }}</span>
            </div>
          </div>
          <div class="item_desc">
            {{ huikui_list_item.desc }}
            <span class="item_tip fs-14">
              {{ huikui_list_item.tip }}
            </span>
          </div>
        </div>
      </div>
      <el-button
        @click="dialogVisible = !dialogVisible"
        type="primary"
        plain
        round
        class="question_button"
        >{{ $t(`marketMall.${market}Market.questionButton`) }}</el-button
      >
    </div>
    <div class="buy_now">
      <el-button type="primary" class="buy_button" @click="goBuy">{{
        $t(`marketMall.${market}Market.buy_now`)
      }}</el-button>
    </div>
    <!-- 常见问题 -->
    <empty-dialog v-model="dialogVisible" width="560px">
      <div class="question_content">
        <div class="dialog_title">
          {{ $t(`marketMall.${market}Market.question_title`) }}
        </div>
        <div class="answer_body">
          <div
            v-for="(question, questionI) in $t(
              `marketMall.${market}Market.question_list`
            )"
            :key="questionI"
          >
            <div class="q">
              {{ question.q }}
            </div>
            <div class="a">
              {{ question.a }}
            </div>
          </div>
        </div>
        <div class="answer_footer">
          {{ $t(`marketMall.${market}Market.answer_footer`) }}
        </div>
      </div>
    </empty-dialog>
    <!-- 是否为专业用户 -->
    <empty-dialog v-model="showPiStatus" width="560px">
      <div class="piStatus_content question_content">
        <div class="dialog_title">
          {{ $t(`marketMall.${market}Market.piStatus_title`) }}
        </div>
        <div
          class="answer_body piStatus_body"
          v-html="$t(`marketMall.${market}Market.piStatus_content`)"
        ></div>
        <div class="piStatus_footer flex flex-vc flex-jend">
          <el-button class="buy_button" @click="choosePi(1)">{{
            $t(`marketMall.${market}Market.piStatus_lButton`)
          }}</el-button>
          <el-button type="primary" class="buy_button" @click="choosePi(0)">{{
            $t(`marketMall.${market}Market.piStatus_RButton`)
          }}</el-button>
        </div>
      </div>
    </empty-dialog>
  </div>
</template>
<script>
import ImgDiv from "@/components/base/ImgDiv.vue";
import EmptyDialog from "@/components/business/EmptyDialog.vue";
import { mapGetters, mapMutations } from "vuex";
import api from "@/api";
export default {
  components: {
    ImgDiv,
    EmptyDialog,
  },
  computed: {
    market() {
      return this.$route.query.market || "hk";
    },
    ...mapGetters(["userInfo", "userAccountList"]),
    disableUser() {
      const c = this.userAccountList.length < 1;
      return c;
    },
  },
  data() {
    return {
      dialogVisible: false,
      showPiStatus: false,
    };
  },
  methods: {
    choosePi(user_type) {
      this.$router.push({
        path: "/mall/buy",
        query: { market: this.market, user_type },
      });
    },
    async goBuy() {
      const { token } = this.userInfo;
      if (!token) {
        this.SET_LOGIN_VISIBLE(true);
        return;
      }
      const userInfo = await api.user.v2UserInfo();
      console.log(userInfo);
      this.SET_USER_INFO({ ...this.userInfo, ...userInfo });
      const list = await api.user.userTradeAccountList();
      this.SET_USER_ACCOUNT_LIST(list);
      const { is_set_pwd, ext = {} } = this.userInfo;
      let message = "";
      if (ext.open_status !== "Y") {
        message = this.$t(`marketMall.${this.market}Market.open_status_text`);
      } else if (is_set_pwd !== 1) {
        message = this.$t(`marketMall.${this.market}Market.open_status_text2`);
      } else if (this.disableUser) {
        message = this.$t(`marketMall.${this.market}Market.open_status_text3`);
      }
      if (message) {
        this.$confirm(message, "", {
          confirmButtonText: this.$t(`common.defineText`),
          confirmButtonType: "text",
          cancelButtonText: this.$t(
            `marketMall.${this.market}Market.cancelText`
          ),
          customClass: "confirm_empty single_button",
          dangerouslyUseHTMLString: true,
          showClose: false,
          showCancelButton: false,
        });
        throw new Error("条件不符");
      }
      if (this.market && this.market.toLowerCase() === "us") {
        this.showPiStatus = true;
      } else {
        this.$router.push({
          path: "/mall/buy",
          query: { market: this.market },
        });
      }
    },
    ...mapMutations("user", [
      "SET_LOGIN_VISIBLE",
      "SET_USER_INFO",
      "SET_USER_ACCOUNT_LIST",
    ]),
  },
};
</script>
<style lang="scss" scoped>
.hk_market {
  background-color: var(--bgGray);
  padding-bottom: 105px;
  .bg_lightBlue {
    background-color: var(--lightBlue) !important;
  }
  .use_iconClass {
    margin-right: 4px;
  }
  .table {
    border-radius: 16px;
    overflow: hidden;
    margin-top: 40px;
    .td {
      height: 70px;
      border-right: 1px solid rgba(0, 0, 0, 0.12);
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }
    .thead {
      background-color: var(--lightBlue) !important;
    }
    .tr .td:last-child {
      border-right: none;
    }
    .tbody .tr:last-child .td {
      border-bottom: none;
    }
    .tbody .td {
      background-color: var(--whiteColor);
    }
  }
  .use_img_list {
    width: 100%;
    margin-top: 80px;
    &_item {
      .top_img {
        width: 96px;
      }
      .bottom {
        padding: 0 15px;
        background-color: var(--whiteColor);
        border-radius: 16px;
        margin-top: 18px;
      }
      .bottom_img {
        width: 240px;
      }
    }
  }
  .huikui_title {
    margin-top: 80px;
    width: 312px;
  }
  .huikui_desc {
    width: 580px;
    font-size: 14px;
    color: var(--explainColor);
    text-align: center;
    line-height: 22px;
    margin-top: 10px;
    span {
      color: var(--fontYellow);
    }
  }
  .huikui_list {
    margin-top: 33px;
    .huikui_list_item {
      background-color: var(--whiteColor);
      border-radius: 8px;
      width: 550px;
      padding: 88px 24px 40px;
      position: relative;
      .item_title {
        text-align: center;
        padding: 11px 79px;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        background: url(../../../assets/v2/marketMall/bg@3x.png) center center
          no-repeat;
        background-size: 100% 100%;
      }
      .item_img {
        position: relative;
        .right_top_icon {
          width: 24px;
          position: absolute;
          right: 5px;
          top: -5px;
        }
        .main_img {
          width: 88px;
        }
      }
      .item_img_title {
        margin-top: 5px;
      }
      .item_desc {
        margin-top: 40px;
      }
      .item_tip {
        color: var(--fontYellow);
        margin-top: 10px;
      }
    }
  }
  .question_button {
    color: var(--blue);
    background: none;
    // border-color: rgba(0, 0, 0, 0.25);
    border: none;
    display: block;
    margin: 40px auto 0;
    padding-top: 9px;
    padding-bottom: 9px;
    width: 140px;
    position: relative;
    font-size: 14px;
    line-height: 22px;
    &::after {
      position: absolute;
      box-sizing: border-box;
      content: " ";
      pointer-events: none;
      top: -50%;
      right: -50%;
      bottom: -50%;
      left: -50%;
      border: 1px solid var(--borderColor);
      -webkit-transform: scale(0.5);
      transform: scale(0.5);
      border-radius: 100px;
    }
  }
  .buy_now {
    background: var(--whiteColor);
    padding: 22px 0;
    width: 100%;
    // margin-top: 40px;
    position: fixed;
    left: 0;
    bottom: 0;
    .buy_button {
      display: block;
      margin: 0px auto 0;
      font-size: 16px;
      background-color: var(--blue);
      padding-top: 11px;
      padding-bottom: 11px;
      width: 343px;
      border-radius: 100px;
    }
  }
  .piStatus_body {
    padding-bottom: 21px;
  }
  .piStatus_footer {
    padding-top: 15px;
    border-top: 1px solid #eef1f3;
    ::v-deep .el-button {
      line-height: 20px;
      padding: 5px 15px;
    }
  }

  .question_content {
    background: var(--whiteColor);
    border-radius: 8px;
    padding: 0px 32px 21px;
    color: var(--blackColor);
    font-size: 14px;
    .dialog_title {
      padding: 16px 0;
      font-size: 16px;
      line-height: 22px;
      font-weight: bold;
      border-bottom: 1px solid #eef1f3;
    }
    .answer_body {
      max-height: 536px;
      overflow-y: auto;
      padding-top: 21px;
      .q {
        font-weight: bold;
      }
      .a {
        color: #989ea7;
        margin-top: 9px;
        padding-bottom: 18px;
      }
    }
    .answer_footer {
      font-size: 14px;
      color: #989ea7;
      padding-top: 3px;
    }
  }
  .piStatus_content {
    padding-bottom: 7px;
  }
}
</style>
